import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import HeroGrid from "../components/grid/heroGrid"
import Divider from "../components/divider/divider"

const heroItems = [
  {
    title: "Strategy",
    description:
      "Plan with the right technologies and knowledge to ensure the best outcome of your business' digital journey.",
    btnLabel: "Explore Strategy",
    btnHref: "/services/strategy",
  },
  {
    title: "Product Development",
    description:
      "Create unique customer experiences that bring meaning and welcome agility in innovation for all business stakeholders.",
    btnLabel: "Explore Product Development",
    btnHref: "/services/product-development",
  },
  {
    title: "Digital Content",
    description:
      "Build a large digital community and loyal base through thoughtful digital content destined for the right target audience.",
    btnLabel: "Explore Digital Content",
    btnHref: "/services/digital-content",
  },
]

const description =
  "Try our digital transformation services on Strategy, Product Development, and Digital Content. Why? Need to plan with the right technologies and knowledge? Need to create a unique customer experience? Need to build a large digital community?"

const ServicesPage = () => {
  return (
    <Layout>
      <Seo title="Services" description={description} />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>Services</h4>
          <p className="subtitle1">{description}</p>
        </div>
        <Divider />
        <div className="mt-5 mb-5 pt-5 pb-5">
          <HeroGrid items={heroItems} />
        </div>
      </section>
    </Layout>
  )
}

export default ServicesPage
